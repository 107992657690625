<template>
  <div class="probetraining">

    <!-- HEADER -->
    <div class="probetraining__header">
      <img src="@/assets/ic_probetraining.svg" alt="Probetraining">
      <h3 class="text-center">Kostenfreie Inhalte</h3>
      <p class="text-center">Die folgenden freigeschalteten Inhalte kannst du unbegrenzt kostenlos nutzen. Werde <span @click="$router.push('/settings/premium')">Premium-Mitglied</span>, um Zugriff auf alle Inhalte von SportlerPlus zu erhalten.</p>
    </div>

    <!-- TABS -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
        <v-btn @click="activeTab = 'workouts'" :class="activeTab == 'workouts' ? 'is-active' : ''" text v-ripple="false">Workouts</v-btn>
        <v-btn @click="activeTab = 'magazine'" :class="activeTab == 'magazine' ? 'is-active' : ''" text v-ripple="false">Magazin</v-btn>
      </div>
    </div>

    <!-- CONTENT -->
    <div v-if="activeTab == 'playlists'" class="probetraining__content">

      <!-- CREATE PLAYLIST -->
      <router-link v-if="userPlaylists == null || userPlaylists.length == 0" to="/playlists/create/start" class="grab">
        <div class="grab__icon">
          <img src="@/assets/ic_badge_plus.svg" alt="" />
        </div>
        <div class="grab__text">
          Wähle aus über 300 Übungen und erstelle deine individuelle <span>Workout-Playlist!</span>
        </div>
      </router-link>

      <!-- MEINE PLAYLISTS V-ELSE -->
      <div v-else class="section">
        <div class="section__title">
          <h4>Meine Playlists</h4>
        </div>
        <PlaylistPersonalItem v-for="(up, idx) in userPlaylists" :key="up.uid" :inactive="idx > 0 && !premiumStatus.validTo" @startPlaylist="startUserPlaylist(up.uid)" @editPlaylistPersonalItemRestricted="editItem(up.uid, true)" @editPlaylistPersonalItem="editItem(up.uid, false)" :title="up.name" :number="up.items.length"></PlaylistPersonalItem>
        <span @click="handleCreatePlaylist" to="/playlists/create/start" class="contentItem contentItem--button">
          <div class="contentItem__icon">
            <img src="@/assets/contentitem/ic_contentitem_add.svg" alt="" />
          </div>
          <div class="contentItem__content">
            <div class="contentItem__title">
              Neues Workout erstellen
            </div>
          </div>
        </span>
      </div>
    </div>

    <div v-else-if="activeTab == 'workouts'" class="probetraining__content">
      <!-- Workouts -->
      <div class="section">
        <div class="section__title">
          <h4>Workouts</h4>
        </div>
        <template v-for="ti in trialTrainingItems">
            <WorkoutItem  v-if="activityType(ti) == 'workout.WorkoutPage'" :workout="ti" v-bind:key="ti.id"></WorkoutItem>
            <PlaylistItem v-else-if="activityType(ti) == 'exercise.PlaylistPage'" :playlist="ti" v-bind:key="ti.id"></PlaylistItem>
        </template>

        <div class="section" v-for="category in trialCategories" v-bind:key="category.id" >
            <router-link :to="'/playlist/start?category_id=' + category.id">
                <div class="card">
                    <div class="card__title">{{ category.name }}</div>
                    <div class="card__subtitle">{{ category.subtitle }}</div>
                    <img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + category.image_url" alt="" />
                </div>
            </router-link>
        </div>

      </div>
    </div>

    <div v-else-if="activeTab == 'magazine'" class="probetraining__content">
      <!-- Magazin -->
      <div class="section">
        <div class="section__title">
          <h4>Magazin</h4>
        </div>
        <router-link to="/articles">
            <div class="card">
                <div class="card__title">Podcasts, Rezepte uvm.</div>
                <img class="card__image" src="@/assets/Magazin_Kachel.jpg" alt="" />
            </div>
        </router-link>
      </div>
    </div>

    <div class="probetraining__premium">
     <small>Du möchtest mehr Inhalte?</small>

      <!-- PREMIUM BADGE -->
      <div @click="$router.push('/settings/premium')" class="grab">
        <div class="grab__icon">
          <img src="@/assets/ic_badge_premium.svg" alt="" />
        </div>
        <div class="grab__text">
          Nutze alle Vorteile als<br>
          <span>Premium-User!</span>
        </div>
      </div>
    </div>

    <!-- Intro Modal -->
    <VModal name="probetrainingIntroModal" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_probetraining.svg" alt="">
        <h3>
          Probier dich aus!
        </h3>
        <p>
          Hier findest du alle kostenfrei nutzbaren Workouts und Magazin-Inhalte. Zudem kannst du deine erstes individuelles Workout erstellen.
        </p>
        <v-btn @click="closeIntroModal" type="button" class="button button--primary button--small">Los geht's</v-btn>
      </div>
    </VModal>

    <EditPlaylistItemModal :restricted="restrictedEditModal" @playlistDeleted=playlistDeleted :userPlaylist="currentUserPlaylist" name="editPlaylistItemModal"></EditPlaylistItemModal>

    <PremiumHintModal :message="premiumHintText"></PremiumHintModal>

  </div>
</template>

<script>
import PlaylistPersonalItem from "@/components/PlaylistPersonalItem.vue";
import EditPlaylistItemModal from "@/components/EditPlaylistItemModal.vue";
import WorkoutItem from '@/components/WorkoutItem.vue';
import PlaylistItem from '@/components/PlaylistItem.vue';
import PremiumHintModal from '@/components/PremiumHintModal.vue';

export default {
    name: 'Probetraining',
    data() {
        return {
            userPlaylists: null,
            currentUserPlaylist: null,
            playlists: [],
            workouts: [],
            categories: [],
            premiumHintText: null,
            restrictedEditModal: false,
            activeTab: "workouts",
        }
    },
    components: { PlaylistPersonalItem, EditPlaylistItemModal, WorkoutItem, PlaylistItem, PremiumHintModal },
    mounted() {
        this.init();
    },
    computed: {
        trialPlaylists() {
            if (this.playlists) {
                return this.playlists.filter(a => !a.premium_content);
            }
            return [];
        },
        trialWorkouts() {
            if (this.workouts) {
                return this.workouts.filter(a => !a.premium_content);
            }
            return [];
        },
        trialTrainingItems() {
            var trainingItems = this.trialPlaylists.concat(this.trialWorkouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
             });
        },
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        trialCategories() {
            if (this.categories) {
                return this.categories.filter(a => !a.premium_content);
            }
            return [];
        },
    },
    methods: {
        init() {
            this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                this.userPlaylists = res;
            });
            window._spTrial = window._spTrial || {};
            if (!window._spTrial.modalDisplayed) {
                window._spTrial.modalDisplayed = true;
                this.$modal.show("probetrainingIntroModal");
            }
            this.$store.dispatch('fetchCategories').then( () => this.categories = this.$store.getters.getCategories );
            this.$store.dispatch('fetchWorkouts').then( () => this.workouts = this.$store.getters.getWorkouts );
            this.$store.dispatch('fetchPlaylists').then( () => this.playlists = this.$store.getters.getPlaylists );
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
        editItem(uid, restricted) {
            this.restrictedEditModal = restricted;
            let userPlaylist = this.userPlaylists.find(up => up.uid == uid);
            this.currentUserPlaylist = userPlaylist;
            this.$modal.show("editPlaylistItemModal");
		},
		startUserPlaylist(uid) {
            this.$router.push("/playlist/start?user_playlist_id=" + uid);
		},
		playlistDeleted(playlist) {
			this.$modal.hide("editPlaylistItemModal");
			this.userPlaylists = this.userPlaylists.filter(i => i.uid != playlist.uid);
        },
        closeIntroModal() {
            this.$modal.hide("probetrainingIntroModal");
        },
        handleCreatePlaylist() {
			if ((this.userPlaylists != null && this.userPlaylists.length > 0) && Object.keys(this.premiumStatus).length === 0) {
				this.premiumHintText = "Im Probetraining kannst du nur eine eigenes Workout erstellen. Lösche dieses zuerst bevor du eine neues anlegen kannst.";
				this.$modal.show('premiumHint');
			}
			else {
                this.$store.dispatch('initTemporaryPlaylist', {});
				this.$router.push("/playlists/create/start");
			}
		},
    }
}
</script>

<style lang="scss">
  .probetraining {
    a {
        text-decoration: none;
    }

    &__header {
      margin-top: -16px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-height: 91px;
      }

      h3 {
        margin: 25px 0 11px;
      }

      p {
        line-height: 20px;
        opacity: 1;
        color: rgba($colorBlack, .48);
        font-weight: 600;
        margin-bottom: 24px;

        span {
          color: $colorPrimary;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .tabs {
      position: sticky;
      top: 48px;
    }

    &__content {
      margin-top: 27px;

      .grab {
        margin-bottom: 24px;
      }

      .contentItem {
        box-shadow: 0 0 2px 0 rgba(30,29,31,0.16), 0 8px 16px 0 rgba(30,29,31,0.04);

        &--button {
          box-shadow: none;
        }
      }
    }

    &__premium {
      text-align: center;
      margin-top: 25px;

      .grab {
        margin-top: 17px;
      }
    }
  }
</style>